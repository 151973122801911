(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/gametype-folding-fan/assets/javascripts/game-card.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/gametype-folding-fan/assets/javascripts/game-card.js');
"use strict";


const {
  useState
} = React;
const {
  cdn
} = svs.core.config.data.svsconfig;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  RibbonBanner
} = svs.ui.ReactRibbon;
const {
  launchWebClient
} = svs.poker_react.components.common.helpers;
const GameCard = _ref => {
  let {
    isLastCardOfUnevenList,
    card,
    index,
    setTop,
    setRotate,
    setMargin,
    cardsArrLength
  } = _ref;
  const {
    theme,
    bannerVisible,
    bannerText,
    image,
    gametypeText,
    headline,
    gameLink
  } = card;
  const defaultRotate = "rotate(".concat(setRotate, "deg)");
  const [toggleAnimateFoldingFan, setToggleAnimateFoldingFan] = useState(defaultRotate);
  const [zIndex, setZIndex] = useState(index);
  const isMediumBigDevice = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  const src = "".concat(cdn, "/images/poker-react/playing-cards/").concat(image, ".png?version=3"); 

  const enableHighlight = () => {
    setZIndex(cardsArrLength + 1);
    setToggleAnimateFoldingFan("translate(20px, -10px) ".concat(defaultRotate));
  };
  const disableHighlight = () => {
    setZIndex(index);
    setToggleAnimateFoldingFan(defaultRotate);
  };
  if (!isMediumBigDevice) {
    return React.createElement("a", {
      className: "game-card".concat(isLastCardOfUnevenList ? ' game-card-long' : '', " game-card_bg__").concat(theme),
      "data-testid": "poker-game-card",
      onClick: () => launchWebClient({
        gameLink
      })
    }, !isLastCardOfUnevenList ? React.createElement("div", {
      className: "game-card-content-container"
    }, React.createElement("div", {
      className: "game-card-content-container-inner"
    }, React.createElement("div", {
      className: "image-container"
    }, React.createElement("img", {
      alt: "speltyp: ".concat(headline),
      src: src
    })), React.createElement("div", {
      className: "game-card-text-container"
    }, React.createElement("h4", {
      className: "h4"
    }, headline), React.createElement("p", {
      className: "f-content"
    }, gametypeText)))) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "image-container"
    }, React.createElement("img", {
      alt: "speltyp: ".concat(headline),
      src: src
    })), React.createElement("div", {
      className: "game-card-text-container"
    }, React.createElement("h4", {
      className: "h4"
    }, headline), React.createElement("p", {
      className: "f-content"
    }, gametypeText))), bannerVisible && bannerText.length > 0 && React.createElement(RibbonBanner, {
      direction: "left",
      size: "200"
    }, bannerText));
  }
  return React.createElement("a", {
    className: "game-card game-card_bg__".concat(theme),
    "data-testid": "poker-game-card",
    onClick: () => launchWebClient({
      gameLink
    }),
    onMouseEnter: enableHighlight,
    onMouseLeave: disableHighlight,
    style: {
      transform: toggleAnimateFoldingFan,
      zIndex: "".concat(zIndex),
      top: "".concat(setTop, "px"),
      left: "".concat(setMargin, "px")
    }
  }, React.createElement("div", {
    className: "game-card-content-container"
  }, React.createElement("div", {
    className: "game-card-content-container-inner"
  }, React.createElement("div", {
    className: "image-container"
  }, React.createElement("img", {
    alt: "speltyp: ".concat(headline),
    src: src
  })), React.createElement("div", {
    className: "game-card-text-container"
  }, React.createElement("h4", {
    className: "h4"
  }, headline), React.createElement("p", {
    className: "f-content"
  }, gametypeText)))), bannerVisible && bannerText.length > 0 && React.createElement(RibbonBanner, {
    direction: "left",
    size: "200"
  }, bannerText));
};
setGlobal('svs.poker_react.components.GameCard', GameCard);

 })(window);